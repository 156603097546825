/* index.css */

/* Import normalize.css for CSS reset */
@import url('https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css');

/* CSS Variables for Theming */
:root {
  --primary-color: #2c3e50; /* Dark Blue */
  --secondary-color: #27ae60; /* Green */
  --background-color: #f5f5f5;
  --text-color: #333;
  --font-family: 'Arial, sans-serif';
  --heading-font-size: 2rem;
  --text-font-size: 1rem;
}

/* Global Styles */
body {
  font-family: var(--font-family);
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.6;
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--primary-color);
  margin-top: 0;
}

a {
  color: var(--secondary-color);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Accessibility */
img {
  max-width: 100%;
  height: auto;
}

button {
  cursor: pointer;
}

.hidden {
  display: none !important;
}

/* Responsive Utilities */
.container {
  width: 90%;
  margin: auto;
}

@media (min-width: 768px) {
  .container {
    width: 80%;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 70%;
  }
}

/* Example of a reusable component */
.button-primary {
  background-color: var(--primary-color);
  color: #fff;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  font-size: var(--text-font-size);
  transition: background-color 0.3s ease;
}

.button-primary:hover {
  background-color: #1a252f; /* Slightly darker shade */
}

/* Additional styles can be added below */
